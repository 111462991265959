import PropTypes from "prop-types";
import React from "react";

import ResponsiveMedia from "@/components/ResponsiveMedia";
import ResponsiveVideo from "@/components/ResponsiveVideo";
import useNearbyClubs from "@/hooks/useNearbyClubs";

import styles from "./ExploreClubs.module.scss";

const ExploreClubs = ({ data }) => {
  const {
    applyEqxPlusAppModuleStyling,
    backgroundMedia,
    cta,
    description,
    enableRegionalVideo,
    title,
  } = data;
  const { isLoading: isLoadingNearbyClubs, nearbyClubs } = useNearbyClubs();

  const nearestClub = nearbyClubs?.[0];

  return (
    <section
      className={styles.exploreClubs}
      data-eqx-plus-app={!!applyEqxPlusAppModuleStyling}
      data-is="ExploreClubs"
    >
      <div className={styles.content}>
        <div className={styles.text}>
          <h2 className={styles.title}>{title}</h2>

          <div className={styles.description}>{description}</div>

          <a className={styles.cta} href={cta?.fields?.url}>
            {cta?.fields?.text}
          </a>
        </div>
      </div>

      {!isLoadingNearbyClubs &&
        enableRegionalVideo &&
        nearestClub?.mobileVideo &&
        nearestClub?.desktopVideo && (
          <ResponsiveVideo
            {...backgroundMedia?.fields}
            className={styles.media}
            defaultVideo={nearestClub?.mobileVideo?.fields?.videoFile}
            mdVideo={nearestClub?.desktopVideo?.fields?.videoFile}
          />
        )}

      {!isLoadingNearbyClubs &&
        (!enableRegionalVideo ||
          !nearestClub?.mobileVideo ||
          !nearestClub?.desktopVideo) && (
          <ResponsiveMedia className={styles.media} media={backgroundMedia} />
        )}
    </section>
  );
};

ExploreClubs.propTypes = {
  data: PropTypes.object,
};

export default ExploreClubs;
